import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as AOS from 'aos';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TermsComponent } from './sections/info/terms/terms.component';
import { DeviceService } from './services/device.service';
import { LocalService } from './services/local.service';

let lastScrollTop = 0;
let delta = 5;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'r8';

  constructor(
    private deviceService: DeviceService,
    private messageService: MessageService,
    private localService: LocalService,
    public route: ActivatedRoute,
    public dialogService: DialogService
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.redirect_status !== undefined) {
        const succeeded = params.redirect_status === 'succeeded' ? true : false;
        if (succeeded) {
          this.messageService.add({
            severity: 'success',
            summary: 'Transaktion erfolgreich',
            detail: 'Die Transaktion war erfolgreich.',
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Transaktion fehlgeschlagen',
            detail:
              'Transaktion fehlgeschlage. Bitte versuchen Sie es mit einer anderen Bezahlmethode erneut.',
          });
        }
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  scrolled() {
    const st = window.scrollY;
    const navbar = document.getElementsByTagName('nav')[0];

    if (navbar != null && !this.deviceService.isMediumDevice()) {
      if (Math.abs(lastScrollTop - st) <= delta) {
        if (navbar.classList.contains('scrolled-navigation')) {
          navbar.classList.remove('scrolled-navigation');
        }
        return;
      }

      navbar.classList.add('scrolled-navigation');
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    this.deviceService.height = window.innerHeight;
    this.deviceService.width = window.innerWidth;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      AOS.init({ once: true });
    }, 120);

    setTimeout(() => {
      const wasTermShowed = +this.localService.getData(
        this.localService.TERMKSEY
      );
      if (wasTermShowed == null || !wasTermShowed) {
        this.dialogService.open(TermsComponent, {
          header: 'Teilnahmebedingungen',
        });

        this.localService.saveData(this.localService.TERMKSEY, '1');
      }
    }, 1000);
  }
}
