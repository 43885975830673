import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DeviceService } from 'src/app/services/device.service';
import { TermsComponent } from './terms/terms.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  constructor(
    public deviceService: DeviceService,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  openTermsModal() {
    this.dialogService.open(TermsComponent, {
      header: 'Teilnahmebedingungen',
    });
  }
}
