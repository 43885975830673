import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-enter',
  templateUrl: './enter.component.html',
  styleUrls: ['./enter.component.scss'],
})
export class EnterComponent implements OnInit {
  baseUrl: string = 'https://api.successmentality.eu/api/v1/lottery/activate';

  checkoutForm = this.fb.group({
    code: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    ],
    check: [false, Validators.requiredTrue],
  });

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  async sumbit() {
    try {
      const body = {
        email: this.email.value!,
        code: this.code.value!,
      };
      await this.http.post(this.baseUrl, body).pipe(retry(1)).toPromise();

      this.showMessage(
        'success',
        'Erfolgreich',
        'Der Code wurde erfolgreich eingelöst!'
      );
    } catch (e) {
      this.showMessage('error', e.error.error.message, '');
    } finally {
      this.checkoutForm.controls['code'].reset();
    }
  }

  canSendRequest() {
    let retValue = false;

    retValue = !!this.code && !!this.email;

    return retValue;
  }

  showMessage(severity: string, summary: string, details: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: details,
    });
  }

  get email() {
    return this.checkoutForm.get('email');
  }
  get code() {
    return this.checkoutForm.get('code');
  }
}
