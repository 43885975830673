<nav
  class="navbar navbar-expand-lg fixed-top"
  [ngClass]="deviceService.isMediumDevice() ? 'scrolled-navigation' : ''"
>
  <div class="container">
    <a class="navbar-brand" href="#">R8 - Gewinnspiel</a>
    <button
      *ngIf="!isNotDashboard"
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      *ngIf="!isNotDashboard"
      class="collapse navbar-collapse justify-content-end"
      id="navbarNav"
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            class="nav-link position-relative"
            (click)="scrollToId('tiles')"
            style="cursor: pointer"
            >Daten</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link position-relative"
            (click)="scrollToId('info')"
            style="cursor: pointer"
            >Gewinnspiel</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
