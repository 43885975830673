import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/services/device.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
  currentDate = new Date().toLocaleDateString();
  ticketPrice = environment.ticketPrice;

  constructor(public deviceService: DeviceService) {
  }

  ngOnInit(): void {}
}
