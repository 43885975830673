<app-navbar [isNotDashboard]="true"></app-navbar>
<app-header [isNotDashboard]="true" title="AGBs"></app-header>
<div class="section" style="background-color: #f7f7f7">
  <div class="container">
    <div class="info">
      <p class="mt-3 mb-5">
        Die nachfolgenden Teilnahmebedingungen gelten für das Gewinnspiel der
        Domain successmentality.eu (nachfolgend Veranstalter-Domain genannt),
        welches die Network Pro Team EOOD (nachfolgend Veranstalter genannt),
        Petar Slabakov 2, j.k. Briz. Varna 9010 veranstaltet. Weitere
        Kontaktdaten finden Sie im Impressum.
        <br /><br />
        Das Gewinnspiel wird für die auf der Veranstalter-Domain der im
        Impressum genannten Firma betrieben. Art und Umfang der Gewinne, die
        Teilnahmetermine und der Vergabemodus der Gewinne ist der
        Gewinnspielankündigung des Gewinnspielveranstalters zu entnehmen.
        <br /><br />
        Aus Gründen der besseren Lesbarkeit wird auf die gleichzeitige
        Verwendung der Sprachformen männlich, weiblich und divers (m/w/d)
        verzichtet. Sämtliche Personenbezeichnungen gelten gleichermaßen für
        alle Geschlechter.
      </p>
    </div>

    <ol class="list-decimal mt-10">
      <li class="header">
        Geltungsbereich

        <div class="mt-5">
          Das Gewinnspiel wird im Zusammenhang eines
          Videotrainings-Programms-Bundle zur freiwilligen Teilnahme angeboten.
          Das Videotraining-Programm-Bundle kann nur im Ganzen erworben werden.
          Jedes gekaufte Video-Bundle bietet die einmalige Chance am Gewinnspiel
          teilzunehmen. Eine Teilnahme am Gewinnspiel ist freiwillig und erfolgt
          demzufolge nicht automatisch mit dem Kauf des Bundles.
        </div>
        <ol class="mt-5">
          <li>
            Die vorliegenden Teilnahmebedingungen gelten für das Gewinnspiel,
            welches auf der Veranstalter-Domain ausgelobt wird.
          </li>
          <li class="mt-5">
            Die nachfolgenden Teilnahmebedingungen enthalten die zwischen dem
            Teilnehmer/der Teilnehmerin am Gewinnspiel und dem Veranstalter
            ausschließlich geltenden Bedingungen, soweit diese nicht durch
            schriftliche Vereinbarungen gem. Ziffer 14.1. dieser Bedingungen
            zwischen den Parteien abgeändert werden.
          </li>
          <li class="mt-5">
            Eine Teilnahme am Gewinnspiel ist nur unter Einbeziehung dieser
            Teilnahmebedingungen und der AGBs beim Kaufabschluss des
            Videotrainings möglich. Vor der Teilnahme wird der Teilnehmer/die
            Teilnehmerin auf die Teilnahmebedingungen in gesetzeskonformer Weise
            hingewiesen.
          </li>
          <li class="mt-5">
            Erfolgt eine Teilnahme am Gewinnspiel, so gelten die zu diesem
            Zeitpunkt aktuellen und angezeigten Teilnahmebedingungen.
          </li>
          <li class="mt-5">
            Die Teilnahmebedingungen gelten auch dann, wenn der Zugriff auf die
            Plattform von außerhalb der Bundesrepublik Deutschland erfolgt.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Teilnahmeberechtigte

        <ol>
          <li class="mt-5">
            Teilnahmeberechtigt sind nur Käufer des Video-Bundles. Der
            Teilnehmer muss das Video-Bundle innerhalb folgender Zeiträume
            gekauft haben: 1. Zeitraum: 15. - 17.11.2023 & 2. Zeitraum: 01. -
            04.12.2023. Die Preise des Bundles erfährt man im Webshop von
            elopage.
          </li>
          <li class="mt-5">
            Eine wirksame Teilnahme am Gewinnspiel erfolgt nur, wenn die
            teilnehmende Person zum Zeitpunkt ihrer Teilnahme das 18. Lebensjahr
            vollendet hat und eine eigene E-Mail Adresse besitzt.
          </li>
          <li class="mt-5">
            Nicht teilnahmeberechtigt sind Mitarbeiter, Bevollmächtigte und
            Beauftragte des Veranstalters und deren jeweiligen Familien- und
            Haushaltsmitglieder sowie alle Personen, die gemäß Nummer 8 der
            Teilnahmebedingungen disqualifiziert oder von dem Gewinnspiel
            ausgeschlossen bzw. gesperrt sind. Bei Missachtung dieser
            Voraussetzungen entfallen sämtliche Ansprüche gegen den
            Veranstalter.
          </li>
          <li class="mt-5">
            Eine Teilnahme ist nur unter Angabe korrekter Nutzer-Daten möglich.
            Der Teilnehmer/die Teilnehmerin haftet für die falsche Angabe
            persönlicher Daten und hat in dieser Beziehung den Veranstalter von
            Ansprüchen Dritter freizustellen. Eine Teilnahme für Strohmänner ist
            nicht erlaubt.
          </li>
          <li class="mt-5">
            Eine natürliche Person darf nur einmal teilnehmen, auch wenn mehrere
            Bundles gekauft wurden. Ein Mehrfachbezug des Bundles und die
            Registrierung des Codes ist nicht gestattet und führt zum Ausschluss
            der Teilnahme.
          </li>
          <li class="mt-5">
            Der Teilnehmer muss aus einem Land in Europa kommen.
          </li>
          <li class="mt-5">
            Bitte beachten Sie, dass bei der Teilnahme die unter Ziffer 3.
            genannten Daten anzugeben sind. Die zur Durchführung und Abwicklung
            des Gewinnspiels erforderlichen Daten werden von uns gemäß den
            gesetzlichen Bestimmungen erhoben und gespeichert. Einzelheiten
            entnehmen Sie bitte den gesonderten
            <span class="underline">Datenschutzbestimmungen</span>.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Teilnahme
        <ol>
          <li class="mt-5">
            Der Kauf des Bundles setzt nicht die verpflichtende Teilnahme am
            Gewinnspiel voraus. Die Teilnahme am Gewinnspiel ist kostenfrei und
            freiwillig.
          </li>
          <li class="mt-5">
            Den Code für die Teilnahme am Gewinnspiel erhält jeder Käufer nach
            dem Kauf des Bundles per E-Mail.
          </li>
          <li class="mt-5">
            Die Teilnahme am Gewinnspiel erfolgt über eine separate Landingpage.
          </li>
          <li class="mt-5">
            Bei der Registrierung sind folgende Daten notwendig: Vor- und
            Nachname, Geburtsdatum, E-Mail-Adresse und die Anschrift.
          </li>
          <li class="mt-5">
            Jeder ordnungsgemäß und rechtzeitig registrierte Teilnehmer nimmt an
            der Ziehung mit einem Teilnahmecodes gemäß Ankündigung beim
            Gewinnspiel teil.
          </li>
          <li class="mt-5">
            Die Teilnahme am Gewinnspiel ist vom 15.11.2023 bis zum 15.12.2023
            23:59 Uhr möglich.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Verlosung und Gewinne
        <ol>
          <li class="mt-5">
            Das Startdatum des Gewinnspiels ist der 15.11.2023
          </li>
          <li class="mt-5">
            Der Teilnahmeschluss ist der 15.12.2023 23:59 Uhr.
          </li>
          <li class="mt-5">
            Der Ziehungstag des Gewinns wird nach dem Teilnahmeschluss
            bekanntgegeben.
          </li>
          <li class="mt-5">
            Der Gewinn ist ein Audi R8 im Wert von 115.000€. Das Fahrzeug wird
            nicht zugelassen übergeben.
          </li>
          <li class="mt-5">Es gibt maximal 1.000 Video-Bundles zu kaufen.</li>
          <li class="mt-5">
            Nach Beendigung des Gewinnspiels wird der Audi R8 mit dem Wert von
            115.000€ unter einen der registrierten Teilnehmer gezogen und durch
            Losentscheid ermittelt. Die Ziehung des Gewinners wird von einem in
            Deutschland ansässigen Notars durchgeführt. Der Notar wird vor der
            Ziehung bekannt gegeben und bestätigt die Rechtmäßigkeit. Der
            Gewinnername wird in einem versiegelten Umschlag festgehalten und
            auf dem Live-Event verkündet.
          </li>
          <li class="mt-5">
            Zu dem Live-Event werden maximal 250 Teilnehmer zufällig eingeladen.
          </li>
          <li class="mt-5">
            Das Datum des Live-Events wird mindestens einen Monat vor dem
            Live-Event bekannt gegeben.
          </li>
          <li class="mt-5">
            Der Gewinner des Audi R8 wird ohne Kenntnis über den Gewinn mit
            einer neutralen Einladung zu dem Live-Event eingeladen.
          </li>
          <li class="mt-5">
            Sollte der Gewinner nicht zu dem Event kommen wollen oder können, so
            verfällt der Anspruch auf den Gewinn. In diesem Fall wird aus den
            zum Ziehungstag vorliegenden Teilnehmern ein Ersatzgewinner
            ausgelost.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Benachrichtigung der Teilnehmer, Gewinner und Gewinnübergabe
        <ol>
          <li class="mt-5">
            Der Gewinner wird durch den Veranstalter persönlich auf dem
            Live-Event benachricht.
          </li>
          <li class="mt-5">
            Für den Gewinnübertrag des Audi R8 wird ein Überlassungsvertrag
            zwischen der Network Pro Team EOOD und dem Gewinner geschlossen.
            Falls der Überlassungsvertrag vom Gewinner nicht unterzeichnet wird,
            ist eine Gewinnübergabe ausgeschlossen.
          </li>
          <li class="mt-5">
            Der ausgeschriebene Gewinn richten sich nach dem Gewinnspiel. Es
            gelten die bei der Ausschreibung festgelegten Regeln.
          </li>
          <li class="mt-5">
            Der Teilnehmer/die Teilnehmerin gibt dem Veranstalter bzw. dessen
            Dienstleister mit seiner/ihrer Teilnahme die ausdrückliche
            Erlaubnis, im Gewinnfall den Vornamen, Namen, Herkunftsort und ein
            Foto auf einer der Internetseiten vom Veranstalter und dessen
            Dienstleister im Internet zu veröffentlichen.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Wegfall des Gewinnanspruchs
        <div class="mt-5">
          Sollte eine Gewinnbenachrichtigung scheitern, weil unter den
          angegebenen Adressdaten eine Zustellung der Gewinnmitteilung erfolglos
          ist bzw. nicht zugestellt werden konnte oder der Gewinner unter der
          angegebenen Telefonnummer und E-Mail-Adresse mindestens drei Versuchen
          und, soweit diese erfolglos blieben, ohne Rückantwort innerhalb von
          einem Monat nach der letzten Zustellung der Gewinnmitteilung nicht
          erreicht werden konnte, so verfällt der Gewinnanspruch und ein
          Ersatzgewinner wird gezogen.
        </div>
      </li>
      <li class="mt-5 header">
        Begrenzungen und Steuern
        <div class="mt-5">
          Der Gewinnanspruch ist nicht auf Dritte übertragbar. Der Anspruch auf
          Erhalt des Gewinns im Wege des Barerlöses ist ausgeschlossen, soweit
          keine Bargewinne ausgelobt sind. Der Gewinner hat für ggf. anfallende
          Steuern selbst aufzukommen, soweit es sich um eine Steuerforderung der
          Finanzbehörden handelt.
        </div>
      </li>
      <li class="mt-5 header">
        Verhaltensregeln, Disqualifikation und Sperrung
        <ol>
          <li class="mt-5">
            Der Veranstalter bzw. dessen Dienstleister hat das Recht, Teilnehmer
            zu disqualifizieren und von dem Gewinnspiel auszuschließen, die den
            Teilnahmevorgang in einer gegen Treu und Glauben verstoßenden Weise,
            z.B. durch Manipulation der Software oder durch Verstoß gegen die
            Spielregeln beeinflussen oder deren Verhalten in sonstiger Weise die
            Grenzen der Zumutbarkeit überschreitet, z.B. durch Bedrohung oder
            unzumutbare Belästigung (Beleidigung, üble Nachrede, extreme
            Anrufhäufung in Form von „Telefonterror“ usw.) von
            Mitarbeitern/Mitarbeiterinnen des Veranstalters oder dessen
            Dienstleister.
          </li>
          <li class="mt-5">
            Ferner kann der Veranstalter oder dessen Dienstleister auch
            Teilnehmer nach beliebigem Ermessen bereits im Vorfeld von einer
            Teilnahme ausschließen, wenn ein Verstoß gegen die in Ziffer 8.1.
            aufgeführten Verhaltensregeln droht.
          </li>
          <li class="mt-5">
            Der Veranstalter untersagt ebenfalls, dass ein Teilnehmer unter
            verschiedenen Partner IDs und E-Mail-Adressen mehrfach teilnimmt und
            sich mehrere Teilnahmecodes durch Mehrfachkäufe der Videotrainings
            eine Mehrfachteilnahme ermöglicht. Dies hat die Disqualifikation zur
            Folge.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Beendigungs-/<wbr />Änderungsmöglichkeiten
        <ol>
          <li class="mt-5">
            Der Veranstalter behält sich das Recht vor, das Gewinnspiel
            jederzeit ohne Vorankündigung abbrechen oder beenden zu können,
            soweit ein berechtigtes Interesse hieran besteht. Dies kann
            insbesondere bei technischen Problemen oder rechtlichen Bedenken der
            Fall sein.
          </li>
          <li class="mt-5">
            Der Veranstalter behält sich das Recht vor, das Gewinnspiel ganz
            abzubrechen. Dies gilt insbesondere, wenn die Verlosung aus
            irgendwelchen Gründen nicht planmäßig laufen kann, so etwa bei
            Computerviren, bei Fehlern der Soft- und/oder Hardware und/oder aus
            sonstigen technischen und/oder rechtlichen Gründen, welche die
            Verwaltung, die Sicherheit, die Integrität und/oder reguläre und
            ordnungsgemäße Durchführung der Verlosung beeinflussen.
          </li>
          <li class="mt-5">
            Der Veranstalter behält sich das Recht vor, die Erreichbarkeit der
            Veranstalter-Domain einzuschränken, insbesondere aus technischen
            Gründen (z. B. Instandhaltung und Wartung) oder für einen
            vorübergehenden Zeitraum auszusetzen. Auf Grund einer Überlastung
            der Zugangskonten in oder bei Systemstörungen kann es außerdem zu
            kurzfristigen Ausfällen der Webseite, einzelner Dienste oder der
            Zugangskonten kommen. Daraus resultieren keine Ansprüche gegen den
            Veranstalter. Insbesondere wird keine Haftung übernommen, wenn
            E-Mails oder Dateneingaben (in Masken der Webseite) nicht den dort
            aufgestellten Anforderungen entsprechen und infolgedessen vom System
            nicht akzeptiert und/oder angenommen werden. Ferner haftet der
            Veranstalter nicht bei Diebstahl oder der Zerstörung der die Daten
            speichernden Systeme und/oder Speichermedien oder bei der
            unberechtigten Veränderung und/oder Manipulation der Daten in den
            Systemen und/oder auf den Speichermedien durch die Teilnehmer oder
            Dritte.
          </li>
          <li class="mt-5">
            Der Veranstalter behält sich das Recht vor ohne Angabe von Gründen
            das Gewinnspiel zu verlängern.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Rechtsweg
        <div class="mt-5">
          Hinsichtlich der Gewinnspielteilnahme ist der Rechtsweg
          ausgeschlossen. Ein einklagbarer Anspruch auf die Auszahlung der
          Gewinne besteht nicht.
        </div>
      </li>
      <li class="mt-5 header">
        Datenschutz und Einwilligung
        <div class="mt-5">
          Die Verarbeitung der Nutzerdaten erfolgt mit größter Sorgfalt und
          unter Beachtung der gesetzlichen Bestimmungen. Die Verarbeitung der in
          das Teilnahmeformular eingegebenen Daten erfolgt ausschließlich auf
          Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), die sie uns
          durch Ihre freiwillige Teilnahme an diesem Gewinnspiel erteilen.
          Nähere Einzelheiten ergeben sich aus der Datenschutzerklärung.
        </div>
      </li>
      <li class="mt-5 header">
        Haftung
        <ol>
          <li class="mt-5">
            Der Veranstalter hat im Falle einer Verlinkung auf die
            Internetseiten von Partnern, Sponsoren und Werbepartnern keinen
            Einfluss auf die verlinkten Seiten. Er macht sich deren Inhalte
            nicht zu Eigen.
          </li>
          <li class="mt-5">
            Die Haftung für Datenverlust wird auf den typischen
            Wiederherstellungsaufwand beschränkt, der bei regelmäßiger und
            gefahrentsprechender Anfertigung von Sicherungskopien eingetreten
            wäre.
          </li>
        </ol>
      </li>
      <li class="mt-5 header">
        Salvatorische Klausel
        <div class="mt-5">
          Sollten einzelne Bestimmungen dieses Vertrages unwirksam oder
          undurchführbar sein oder nach Vertragsschluss unwirksam oder
          undurchführbar werden, bleibt davon die Wirksamkeit des Vertrages im
          Übrigen unberührt. An die Stelle der unwirksamen oder undurchführbaren
          Bestimmung soll diejenige wirksame und durchführbare Regelung treten,
          deren Wirkungen der wirtschaftlichen Zielsetzung am nächsten kommen,
          die die Vertragsparteien mit der unwirksamen bzw. undurchführbaren
          Bestimmung verfolgt haben. Die vorstehenden Bestimmungen gelten
          entsprechend für den Fall, dass sich der Vertrag als lückenhaft
          erweist.
        </div>
      </li>
      <li class="mt-5 header">
        Schlussbestimmungen
        <ol>
          <li class="mt-5">
            Änderungen oder Ergänzungen dieser Teilnahmebedingungen bedürfen der
            Schriftform. Dies gilt auch für die Aufhebung des
            Schriftformerfordernisses. Das Gewinnspiel unterliegt ausschließlich
            dem Recht der Bundesrepublik Deutschland.
          </li>
          <li class="mt-5">
            Sofern der Teilnehmer/die Teilnehmerin Kaufmann bzw. Kauffrau im
            Sinne des Handelsgesetzbuches, juristische Person des öffentlichen
            Rechts oder öffentlich-rechtliches Sondervermögen ist, wird für
            sämtliche Streitigkeiten, die im Rahmen der Abwicklung dieses
            Vertragsverhältnisses entstehen, der Gerichtsstand des
            Geschäftssitzes des Veranstalters als Gerichtsstand vereinbart.
          </li>
        </ol>
      </li>
    </ol>
    <p class="mt-5">Varna, im November 2023</p>
  </div>
</div>
<app-footer></app-footer>
