import { Injectable } from '@angular/core';

enum ImageDevice {
  DESKTOP,
  MOBILE,
}

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  desktopImages = new Array();
  mobileImages = new Array();

  constructor() {
    this.pload(
      ImageDevice.DESKTOP,
      '/assets/images/R8.jpg',
      '/assets/images/R8_8.jpg',
      '/assets/images/R8_7.jpg',
      '/assets/images/R8_1.jpg',
      '/assets/images/R8_9.jpg',
      '/assets/images/R8_6.jpg',
      '/assets/images/R8_4.jpg',
      '/assets/images/R8_2.jpg',
      '/assets/images/R8_3.jpg',
      '/assets/images/R8_5.jpg'
    );
    this.pload(
      ImageDevice.MOBILE,
      '/assets/images/mobile/R8.jpg',
      '/assets/images/mobile/R8_1.jpeg',
      '/assets/images/mobile/R8_2.jpeg',
      '/assets/images/mobile/R8_3.jpg',
      '/assets/images/mobile/R8_4.jpg',
      '/assets/images/mobile/R8_5.jpeg',
      '/assets/images/mobile/R8_6.jpeg',
      '/assets/images/mobile/R8_7.jpeg',
      '/assets/images/mobile/R8_8.jpeg',
      '/assets/images/mobile/R8_9.jpeg',
    );
  }

  pload(imageDevice: ImageDevice, ...args: any[]): void {
    switch (imageDevice) {
      case ImageDevice.DESKTOP:
        for (let i = 0; i < args.length; i++) {
          this.desktopImages[i] = new Image();
          this.desktopImages[i].src = args[i];
        }
        break;
      case ImageDevice.MOBILE:
        for (let i = 0; i < args.length; i++) {
          this.mobileImages[i] = new Image();
          this.mobileImages[i].src = args[i];
        }
        break;
    }
  }
}
