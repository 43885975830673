<p>Voraussetzungen für die Verlosung:</p>
<ol>
  <li>
    Nur Personen, die mindestens <span class="fw-bold">18 Jahre</span> alt sind
  </li>
  <li>
    Codes können bis spätestens
    <span class="fw-bold">11.12.2023</span> eingelöst werden
  </li>
  <li>AGBs der Verlosung wurden akzeptiert</li>
</ol>

<!-- <button
  pButton
  type="button"
  label="Verstanden"
  (click)="close()"
  class="mt-2 float-end"
></button> -->
