import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  width: number;
  height: number;

  smallWidth = 768;
  mediumWidth = 992;

  isMobileDevice(): boolean {
    return this.width < this.smallWidth;
  }

  isMediumDevice(): boolean {
    return this.width < this.mediumWidth;
  }
}
