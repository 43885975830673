<app-navbar [isNotDashboard]="true"></app-navbar>
<app-header [isNotDashboard]="true" title="Impressum"></app-header>
<div class="section" style="background-color: #f7f7f7">
  <div class="container">
    <div class="info">
      <h4 class="mt-3 mb-4 fw-bold">Wirtschaftlicher Sitz des Unternehmens:</h4>
      <h2 class="fw-bold">Network Pro Team EOOD</h2>
      <p class="mt-3">Ul. Petar Slabakov 2</p>
      <p>Varna 910 | Bulgaria</p>
      <p>VAT NO: BG207367296</p>
      <div class="my-5"></div>
      <span>Email: </span
      ><a href="mailto:info@successmentality.eu">info(@)successmentality.eu</a>
      <h3 class="my-4">
        <span class="fw-bold">Behörde gem. ECG</span> (E-Commerce Gesetz)
      </h3>
      <p>
        Beschwerdeverfahren: Link Online-Streitbeilegungs-Plattform der EU-
        Kommission:
        <a href="http://ec.europa.eu/consumers/odr/" target="_blank"
          >http://ec.europa.eu/consumers/odr/</a
        >
      </p>
      <p class="mt-3">
        Alternativ können Sie Ihre Beschwerde auch an
        <a href="mailto:info@successmentality.eu">info(@)successmentality.eu</a>
        richten.
      </p>
      <p class="my-5">
        Copyrighthinweise zu verwendeten Bild- und Mediendaten Dritter
        <br /><br />
        Quelle: Canva
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
