import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import * as Rellax from 'rellax';
import { ImageService } from 'src/app/services/image.service';

const animationDuration = 6;

const animations = trigger('imgAnimation', [
  transition(':enter', [
    animate(
      animationDuration + 's',
      keyframes([
        style({ opacity: '0', offset: 0 }),
        style({ transform: 'scale(1)', offset: 0 }),
        style({ opacity: '0.5', offset: 0.25 }),
        style({ transform: 'scale(1.05)', offset: 0.25 }),
        style({ opacity: '1', offset: 0.5 }),
        style({ transform: 'scale(1.1)', offset: 0.5 }),
        style({ opacity: '1', offset: 0.75 }),
        style({ transform: 'scale(1.15)', offset: 0.75 }),
        style({ opacity: '1', offset: 1 }),
        style({ transform: 'scale(1.2)', offset: 1 }),
      ])
    ),
  ]),
  // transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
]);

@Component({
  selector: 'app-header-overlay',
  templateUrl: './header-overlay.component.html',
  styleUrls: ['./header-overlay.component.scss'],
  animations: [animations],
})
export class HeaderOverlayComponent implements OnInit, OnDestroy {
  @Input() showChevron: boolean = false;

  index: number = 0;
  imageIndex: number = 0;
  interval: any;
  animationDuration = animationDuration * 1000;

  constructor(
    public deviceService: DeviceService,
    public imageService: ImageService
  ) {
    this.interval = setInterval(() => {
      this.onIncIndex();
    }, this.animationDuration);
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  ngOnInit(): void {
    new Rellax('.rellax');
  }

  onIncIndex(increment: number = 1) {
    this.index =
      (this.index + this.imageService.desktopImages.length + increment) %
      this.imageService.desktopImages.length;
    this.imageIndex = null;
  }

  onIncIndexResetTimer(increment: number = 1) {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.onIncIndex(increment);
    }, this.animationDuration);

    this.onIncIndex(increment);
  }

  onDone() {
    this.imageIndex = this.index;
  }
}
