<div class="ticket-wrapper w-100">
  <div class="ticket">
    <div class="row g-0 h-100">
      <div class="col-8">
        <div class="front d-flex flex-column justify-content-center justify-content-lg-between">
          <div class="front-bg"></div>
          <div>
            <div class="mt-0 mt-lg-3">R8 - Gewinnspiel</div>
            <div class="big">Ticket</div>
          </div>
          <div *ngIf="!deviceService.isMediumDevice()" class="info d-flex flex-column flex-lg-row justify-content-between">
            <div class="d-flex">
              <section>
                <div class="title">Date</div>
                <div>{{ currentDate }}</div>
              </section>
              <section>
                <div class="title">Email</div>
                <div>max@mustermann.de</div>
              </section>
            </div>
            <section class="end">
              <div class="title">Preis</div>
              <div>€{{ ticketPrice }}</div>
            </section>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="back">
          <div class="number">#1</div>
        </div>
      </div>
    </div>
  </div>
</div>