import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeaderOverlayComponent } from "./header-overlay/header-overlay.component";

@NgModule({
    declarations: [
        HeaderOverlayComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
    ],
    exports: [
        HeaderOverlayComponent
    ]
  })
export class SharedModule{ }
