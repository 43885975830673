<div class="section" style="background-color: #f7f7f7">
  <div class="container">
    <div class="info text-center">
      <div class="info__title text-center">Technische Daten</div>
      <p class="mt-3 mb-5">Audi R8 Spyder Quattro 5.2</p>
    </div>
    <app-tiles></app-tiles>
  </div>
</div>

<div id="info" class="section">
  <div class="container">
    <div class="row mt-5">
      <div
        class="col-lg-6 align-items-center justify-content-center justify-content-lg-start"
        [attr.data-aos]="
          deviceService.isMobileDevice() ? 'fade-up' : 'fade-right'
        "
        data-aos-duration="1000"
      >
        <div class="info">
          <div class="info__title">Gewinnspiel</div>
          <p class="text-center text-lg-start pe-0 pe-lg-5 mt-3">
            <app-terms></app-terms>
          </p>
        </div>
      </div>
      <div
        class="col-lg-6 d-flex align-items-center justify-content-center"
        [attr.data-aos]="
          deviceService.isMobileDevice() ? 'fade-up' : 'fade-left'
        "
        data-aos-duration="1000"
      >
        <div class="w-100 h-100 mt-5 mt-lg-0">
          <app-enter></app-enter>
        </div>
      </div>
    </div>
  </div>
</div>
