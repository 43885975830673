<div
  class="d-flex flex-column align-items-center justify-content-center w-100 h-100"
>
  <form [formGroup]="checkoutForm" (ngSubmit)="sumbit()" class="w-75">
    <span class="p-input-icon-left w-100 mt-2">
      <i class="pi pi-envelope"></i>
      <input
        type="text"
        pInputText
        formControlName="email"
        placeholder="Email"
        class="w-100"
      />
    </span>
    <span class="p-input-icon-left w-100 mt-2">
      <i class="pi pi-pencil"></i>
      <input
        type="text"
        pInputText
        formControlName="code"
        placeholder="Gewinn Code"
        class="w-100"
      />
    </span>
    <label class="mt-2 w-100"
      ><input type="checkbox" formControlName="check" /> Ich habe die
      Nutzungsbedingungen und Datenschutz gelesen und akzeptiert.</label
    >
    <button
      type="submit"
      [disabled]="!checkoutForm.valid"
      icon="pi pi-send"
      pButton
      label="Einlösen"
      class="w-100 mt-2"
    ></button>
  </form>
</div>
