import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { InfoComponent } from './sections/info/info.component';
import { HeaderComponent } from './sections/header/header.component';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FooterComponent } from './shared/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TilesComponent } from './sections/info/tiles/tiles.component';
import { DividerModule } from 'primeng/divider';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TicketComponent } from './sections/info/ticket/ticket.component';
import { SharedModule } from './shared/shared.module';
import { TermsComponent } from './sections/info/terms/terms.component';

import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { EnterComponent } from './sections/info/enter/enter.component';
import { AgbsComponent } from './sections/agbs/agbs.component';
import { ImpressumComponent } from './sections/impressum/impressum.component';
import { DatenschutzComponent } from './sections/datenschutz/datenschutz.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    InfoComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    TilesComponent,
    SpinnerOverlayComponent,
    TicketComponent,
    TermsComponent,
    EnterComponent,
    AgbsComponent,
    ImpressumComponent,
    DatenschutzComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    NgbModule,
    FormsModule,
    DividerModule,
    CardModule,
    InputNumberModule,
    OverlayModule,
    ToastModule,
    SharedModule,
    DynamicDialogModule,
    OverlayPanelModule,
    HttpClientModule,
  ],
  providers: [MessageService, DialogService],
  bootstrap: [AppComponent],
})
export class AppModule {}
