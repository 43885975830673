<div
  class="header-wrapper overlay overlay-dark-40"
  [ngStyle]="{ height: isNotDashboard ? '60vh' : '100vh' }"
>
  <app-header-overlay [showChevron]="false"></app-header-overlay>
  <div *ngIf="!isNotDashboard; else elseBlock" class="container-center">
    <div class="header-text">Network Pro Team</div>
    <div class="header-title">R8</div>
    <div class="header-text">Gewinnspiel</div>
    <button
      pButton
      icon="pi pi-pencil"
      label="Einlösen"
      class="mt-3"
      (click)="scrollToId('info')"
    ></button>
  </div>
  <ng-template #elseBlock>
    <div class="container-center">
      <div class="header-text" style="font-size: 3.5rem">
        {{ title }}
      </div>
    </div>
  </ng-template>
  <div class="mousedown-wrapper">
    <span></span>
  </div>
</div>
