import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() isNotDashboard: boolean = false;
  constructor(private _router: Router, public deviceService: DeviceService) {}

  ngOnInit(): void {}

  scrollToId(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }
}
