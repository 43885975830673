import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { AgbsComponent } from './sections/agbs/agbs.component';
import { ImpressumComponent } from './sections/impressum/impressum.component';
import { DatenschutzComponent } from './sections/datenschutz/datenschutz.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: '', pathMatch: 'full' },
      // { path: '', loadChildren: () => import('./components/steps-checkout/steps-checkout.module').then((m) => m.StepsCheckoutModule) },
    ],
  },
  {
    path: 'agbs',
    component: AgbsComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
