<div class="header-wrapper rellax" data-rellax-speed="-5">
  <div
    *ngIf="!deviceService.isMobileDevice(); else elseBlock"
    class="w-100 h-100"
  >
    <ng-container *ngFor="let img of imageService.desktopImages; index as i">
      <img
        *ngIf="i === imageIndex"
        @imgAnimation
        (@imgAnimation.done)="onDone()"
        [src]="img.src"
        class="header-img"
        style="object-fit: cover"
        alt="R8 Slideshow Image"
      />
    </ng-container>
  </div>
  <ng-template #elseBlock>
    <ng-container *ngFor="let img of imageService.mobileImages; index as i">
      <img
        *ngIf="i === imageIndex"
        @imgAnimation
        (@imgAnimation.done)="onDone()"
        [src]="img.src"
        class="header-img"
        style="object-fit: cover"
        alt="R8 Slideshow Image"
      />
    </ng-container>
  </ng-template>
</div>
<em
  *ngIf="showChevron && !deviceService.isMobileDevice()"
  class="pi pi-chevron-right chevron chevron--right"
  (click)="onIncIndexResetTimer()"
></em>
<em
  *ngIf="showChevron && !deviceService.isMobileDevice()"
  class="pi pi-chevron-left chevron chevron--left"
  (click)="onIncIndexResetTimer(-1)"
></em>
