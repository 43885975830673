<p-overlayPanel
  #impressum
  [dismissable]="true"
  [showCloseIcon]="true"
  [styleClass]="deviceService.isMobileDevice() ? 'mobile-width' : ''"
>
  <ng-template pTemplate>
    <h3>Impressum</h3>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente,
      possimus!
    </p>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel
  #bedingungen
  [dismissable]="true"
  [showCloseIcon]="true"
  [styleClass]="deviceService.isMobileDevice() ? 'mobile-width' : ''"
>
  <ng-template pTemplate>
    <h3>Bedingungen</h3>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente,
      possimus!
    </p>
  </ng-template>
</p-overlayPanel>

<div class="section" style="background-color: rgb(7, 7, 7)">
  <div class="container">
    <div class="d-lg-flex justify-content-between text-white-50">
      <div class="footer-item" routerLink="">Network Pro Team</div>
      <div class="mt-3 mt-lg-0">
        <div class="d-flex flex-column flex-lg-row">
          <div class="footer-item" routerLink="/impressum">Impressum</div>
          <div
            class="footer-item mx-0 my-2 mx-lg-3 my-lg-0"
            routerLink="/datenschutz"
          >
            Datenschutz
          </div>
          <div class="footer-item" routerLink="/agbs">AGBs</div>
        </div>
      </div>
    </div>
  </div>
</div>
