import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent implements OnInit {
  firstAnimation = true;

  @ViewChild('tiles') tiles!: ElementRef;

  ngOnInit(): void {}

  @HostListener('document:scroll', ['$event'])
  public onViewportScroll() {
    const windowHeight = window.innerHeight;

    const boundingRectTiles = this.tiles.nativeElement.getBoundingClientRect();

    const tileBegin = boundingRectTiles.top - windowHeight;
    const tileEnd = boundingRectTiles.bottom;

    if (
      tileBegin < 0  &&
      tileEnd > 0
    ) {
      if (this.firstAnimation) {
        setTimeout(() => {
          this.runAnimations();
        }, 500);
        this.firstAnimation = false;
      }
    }
  }

  // How long you want the animation to take, in ms
  animationDuration = 2000;
  // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
  frameDuration = 1000 / 60;
  // Use that to calculate how many frames we need to complete the animation
  totalFrames = Math.round(this.animationDuration / this.frameDuration);
  // An ease-out function that slows the count as it progresses
  easeOutQuad = (t) => t * (2 - t);

  // The animation function, which takes an Element
  animateCountUp = (el) => {
    let frame = 0;
    const countTo = parseInt(el.dataset.countto, 10);
    // Start the animation running 60 times per second
    const counter = setInterval(() => {
      frame++;
      // Calculate our progress as a value between 0 and 1
      // Pass that value to our easing function to get our
      // progress on a curve
      const progress = this.easeOutQuad(frame / this.totalFrames);
      // Use the progress value to calculate the current count
      const currentCount = Math.round(countTo * progress);

      // If the current count has changed, update the element
      if (parseInt(el.innerHTML, 10) !== currentCount) {
        el.innerHTML = currentCount;
      }

      // If we’ve reached our last frame, stop the animation
      if (frame === this.totalFrames) {
        clearInterval(counter);
      }
    }, this.frameDuration);
  };

  // Run the animation on all elements with a class of ‘countup’
  runAnimations = () => {
    const countupEls = document.querySelectorAll('.countup');
    countupEls.forEach(this.animateCountUp);
  };
}
