<app-navbar [isNotDashboard]="true"></app-navbar>
<app-header [isNotDashboard]="true" title="Datenschutz"></app-header>
<div class="section" style="background-color: #f7f7f7">
  <div class="container">
    <div class="info">
      <ol class="list-decimal mt-10">
        <li class="title">
          Datenschutz auf einem Blick
          <div class="subtitle">Allgemeine Hinweise</div>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <div class="subtitle">Datenerfassung auf dieser Website</div>
          <div class="question">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </div>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </p>
          <div class="question">Wie erfassen wir Ihre Daten?</div>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
            <br />
            <br />
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
            Besuch der Website durch unsere IT- Systeme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </p>
          <div class="question">Wofür nutzen wir Ihre Daten?</div>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <div class="question">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </div>
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            <br />
            <br />
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit an uns wenden.
          </p>
        </li>
        <li class="title">
          Hosting und Content Delivery Networks (CDN)
          <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
          <div class="subtitle">Hetzner</div>
          <p>
            Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710
            Gunzenhausen (nachfolgend Hetzner).
            <br />
            <br />
            Details entnehmen Sie der Datenschutzerklärung von Hetzner:
            <br />
            <a
              href="https://www.hetzner.com/de/rechtliches/datenschutz"
              target="_blank"
              >https://www.hetzner.com/de/rechtliches/datenschutz</a
            >
            <br />
            <br />
            Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
            möglichst zuverlässigen Darstellung unserer Website. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
          </p>
          <div class="subtitle">Amazon CloudFront CDN</div>
          <p>
            Wir nutzen das Content Delivery Network Amazon CloudFront CDN.
            Anbieter ist die Amazon Web Services EMEA SARL, 38 avenue John F.
            Kennedy, L-1855, Luxemburg (nachfolgend „Amazon“).
            <br />
            <br />
            Bei Amazon CloudFront CDN handelt es sich um ein weltweit verteiltes
            Content Delivery Network. Dabei wird technisch der
            Informationstransfer zwischen Ihrem Browser und unserer Website über
            das Content Delivery Network geleitet. Hierdurch können wir die
            weltweite Erreichbarkeit und die Leistungsfähigkeit unserer Website
            erhöhen.
            <br />
            <br />
            Der Einsatz von Amazon CloudFront CDN beruht auf unserem
            berechtigten Interesse an einer möglichst fehlerfreien und sicheren
            Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
            <br />
            <br />
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier:
            <br />
            <a
              href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
              target="_blank"
              >https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/</a
            >
            <br />
            <br />
            Weitere Informationen zu Amazon CloudFront CDN finden Sie hier:
            <br />
            <a
              href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf"
              target="_blank"
              >https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__<wbr />German_Translation.pdf</a
            >
          </p>
        </li>
        <li class="title">
          Allgemeine Hinweise und Pflichtinformationen
          <div class="subtitle">Datenschutz</div>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            <br />
            <br />
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
            <br />
            <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <div class="subtitle">Hinweis zur verantwortlichen Stelle</div>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
            <br />
            <br />
            Network Pro Team EOOD <br />
            Ul. Petar Slabakov 2 <br />
            Varna 910 | Bulgaria
            <br />
            <br />
            Telefon: [Telefonnummer der verantwortlichen Stelle] <br />
            E-Mail:
            <a href="mailto:info@successmentality.eu"
              >info (@) successmentality.eu</a
            >
            <br />
            <br />
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <div class="subtitle">Speicherdauer</div>
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </p>
          <div class="subtitle">
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser Website
          </div>
          <p>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
            Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
            Falle einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die
            Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
            DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
            auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
            eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
            wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
            Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
            Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden Absätzen dieser
            Datenschutzerklärung informiert.
          </p>
          <div class="subtitle">
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </div>
          <p>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
            USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
            diese Drittstaaten übertragen und dort verarbeitet werden. Wir
            weisen darauf hin, dass in diesen Ländern kein mit der EU
            vergleichbares Datenschutzniveau garantiert werden kann.
            Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </p>
          <div class="subtitle">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </div>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <div class="subtitle">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </div>
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
            UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
            <br />
            <br />
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
            ART. 21 ABS. 2 DSGVO).
          </p>
          <div class="subtitle">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </div>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
          <div class="subtitle">Recht auf Datenübertragbarkeit</div>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          <div class="subtitle">Auskunft, Berichtigung und Löschung</div>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </p>
          <div class="subtitle">Recht auf Einschränkung der Verarbeitung</div>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
            <br />
            <br />
          </p>
          <ul class="list-disc sub-list">
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten - von ihrer Speicherung
            abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <div class="subtitle">
            Verschlüsselter Zahlungsverkehr auf dieser Website
          </div>
          <p>
            Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
            Verpflichtung, uns Ihre Zahlungsdaten (z. B. Kontonummer bei
            Einzugsermächtigung) zu übermitteln, werden diese Daten zur
            Zahlungsabwicklung benötigt.
            <br />
            <br />
            Der Zahlungsverkehr über die gängigen Zahlungsmittel
            (Visa/MasterCard, Lastschriftverfahren) erfolgt ausschließlich über
            eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine verschlüsselte
            Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
            „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
            <br /><br />
            Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie
            an uns übermitteln, nicht von Dritten mitgelesen werden.
          </p>
          <div class="subtitle">Widerspruch gegen Werbe-E-Mails</div>
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </p>
        </li>
        <li class="title">
          Datenerfassung auf dieser Website
          <div class="subtitle">Kontaktformular</div>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
            <br /><br />
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
            <br /><br />
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unberührt.
          </p>
          <div class="subtitle">Anfrage per E-Mail, Telefon oder Telefax</div>
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
            <br /><br />
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
            <br /><br />
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen - insbesondere
            gesetzliche Aufbewahrungsfristen - bleiben unberührt.
          </p>
          <div class="subtitle">Registrierung auf dieser Website</div>
          <p>
            Sie können sich auf dieser Website registrieren, um zusätzliche
            Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
            verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes
            oder Dienstes, für den Sie sich registriert haben. Die bei der
            Registrierung abgefragten Pflichtangaben müssen vollständig
            angegeben werden. Anderenfalls werden wir die Registrierung
            ablehnen. <br /><br />
            Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
            notwendigen Änderungen nutzen wir die bei der Registrierung
            angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
            <br /><br />
            Die Verarbeitung der bei der Registrierung eingegebenen Daten
            erfolgt zum Zwecke der Durchführung des durch die Registrierung
            begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer
            Verträge (Art. 6 Abs. 1 lit. b DSGVO).
            <br /><br />
            Die bei der Registrierung erfassten Daten werden von uns
            gespeichert, solange Sie auf dieser Website registriert sind und
            werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen
            bleiben unberührt.
          </p>
        </li>
        <li class="title">
          Newsletter
          <div class="subtitle">Newsletterdaten</div>
          <p>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen
            möchten, benötigen wir von Ihnen eine E- Mail-Adresse sowie
            Informationen, welche uns die Überprüfung gestatten, dass Sie der
            Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des
            Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur
            auf freiwilliger Basis erhoben. Für die Abwicklung der Newsletter
            nutzen wir Newsletterdiensteanbieter, die nachfolgend beschrieben
            werden.
          </p>
          <div class="subtitle">Klick-Tipp</div>
          <p>
            Diese Website nutzt Klick-Tipp für den Versand von Newslettern.
            Anbieter ist die KLICK-TIPP LIMITED, 15 Cambridge Court, 210
            Shepherd’s Bush Road, London W6 7NJ, Vereinigtes Königreich.
            <br /><br />
            Klick-Tipp ist ein Dienst, mit dem u.a. der Versand von Newslettern
            organisiert und analysiert werden kann. Die von Ihnen zum Zwecke des
            Newsletterbezugs eingegeben Daten werden auf den Servern von
            Klick-Tipp gespeichert.
          </p>
          <div class="subtitle">Datenanalyse durch Klick-Tipp</div>
          <p>
            Wenn wir Newsletter mit Hilfe von Klick-Tipp versenden, können wir
            feststellen, ob eine Newsletter- Nachricht geöffnet und welche Links
            ggf. angeklickt wurden.
            <br /><br />
            Klick-Tipp ermöglicht es uns auch, die Newsletter-Empfänger anhand
            verschiedener Kategorien zu unterteilen (sog. Tagging). Dabei lassen
            sich die Newsletterempfänger z. B. nach Geschlecht, persönlichen
            Vorlieben (z. B. Vegetarier oder Nicht-Vegetarier) oder
            Kundenbeziehung (z. B. Kunde oder potenzieller Kunde) unterteilen.
            Auf diese Weise lassen sich die Newsletter besser an die jeweiligen
            Zielgruppen anpassen. Nähere Informationen erhalten Sie unter:
            <br />
            <a href="https://www.klicktipp.com" target="_blank"
              >https://www.klicktipp.com</a
            >
            und <br />
            <a href="https://support.klicktipp.com/" target="_blank"
              >https://support.klicktipp.com/</a
            >
            <br /><br />

            Wenn Sie keine Analyse durch Klick-Tipp wollen, müssen Sie daher den
            Newsletter abbestellen. Hierfür stellen wir in jeder
            Newsletternachricht einen entsprechenden Link zur Verfügung.
          </p>
          <div class="subtitle">Rechtsgrundlage</div>
          <p>
            Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
            widerrufen. Die Rechtmäßigkeit der bereits erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
            <br /><br />
            Großbritannien gilt als datenschutzrechtlich sicherer Drittstaat.
            Das bedeutet, dass Großbritannien ein Datenschutzniveau aufweist,
            das dem Datenschutzniveau in der Europäischen Union entspricht.
          </p>
          <div class="subtitle">Speicherdauer</div>
          <p>
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
            Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
            uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
            Abbestellung des Newsletters aus der Newsletterverteilerliste
            gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden,
            bleiben hiervon unberührt.
            <br /><br />
            Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
            E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
            einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger
            Mailings erforderlich ist. Die Daten aus der Blacklist werden nur
            für diesen Zweck verwendet und nicht mit anderen Daten
            zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem
            Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand
            von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1
            lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht
            befristet.
            <span class="bold">
              Sie können der Speicherung widersprechen, sofern Ihre Interessen
              unser berechtigtes Interesse überwiegen.
            </span>
            <br /><br />
            Näheres entnehmen Sie den Datenschutzbestimmungen von Klick-Tipp
            unter:
            <a
              href="https://www.klicktipp.com/datenschutzerklarung/"
              target="_blank"
            >
              https://www.klicktipp.com/datenschutzerklarung/.</a
            >
          </p>
        </li>
        <li class="title">
          Plugins und Tools
          <div class="subtitle">YouTube mit erweitertem Datenschutz</div>
          <p>
            Diese Website bindet Videos der Website YouTube ein. Betreiber der
            Seiten ist die Google Ireland Limited („Google“), Gordon House,
            Barrow Street, Dublin 4, Irland.
            <br /><br />
            Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
            bewirkt laut YouTube, dass YouTube keine Informationen über die
            Besucher auf dieser Website speichert, bevor diese sich das Video
            ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
            erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
            So stellt YouTube - unabhängig davon, ob Sie sich ein Video ansehen
            - eine Verbindung zum Google DoubleClick-Netzwerk her.
            <br /><br />
            Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
            Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
            YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
            Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
            YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
            zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
            YouTube- Account ausloggen.
            <br /><br />
            Des Weiteren kann YouTube nach Starten eines Videos verschiedene
            Cookies auf Ihrem Endgerät speichern oder vergleichbare
            Wiedererkennungstechnologien (z. B. Device-Fingerprinting)
            einsetzen. Auf diese Weise kann YouTube Informationen über Besucher
            dieser Website erhalten. Diese Informationen werden u. a. verwendet,
            um Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu
            verbessern und Betrugsversuchen vorzubeugen.
            <br /><br />

            Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
            Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
            Einfluss haben.
            <br /><br />
            Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
            <br /><br />
            Weitere Informationen über Datenschutz bei YouTube finden Sie in
            deren Datenschutzerklärung unter:
            <a href="https://policies.google.com/privacy?hl=de" target="_blank">
              https://policies.google.com/privacy?hl=de.</a
            >
          </p>
        </li>
        <li class="title">
          eCommerce und Zahlungsanbieter
          <div class="subtitle">Verarbeiten von Kunden- und Vertragsdaten</div>
          <p>
            Wir erheben, verarbeiten und nutzen personenbezogene Kunden- und
            Vertragsdaten zur Begründung, inhaltlichen Ausgestaltung und
            Änderung unserer Vertragsbeziehungen. Personenbezogene Daten über
            die Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
            verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
            Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
            abzurechnen.
            <br />
            Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO.
            <br /><br />
            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
            Beendigung der Geschäftsbeziehung und Ablauf der ggf. bestehenden
            gesetzlichen Aufbewahrungsfristen gelöscht. Gesetzliche
            Aufbewahrungsfristen bleiben unberührt.
          </p>
          <div class="subtitle">
            Datenübermittlung bei Vertragsschluss für Dienstleistungen und
            digitale Inhalte
          </div>
          <p>
            Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies
            im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der
            Zahlungsabwicklung beauftragte Kreditinstitut.
            <br /><br />
            Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur
            dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine
            Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung,
            etwa zu Zwecken der Werbung, erfolgt nicht.
            <br /><br />
            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
            der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet.
          </p>
          <div class="subtitle">Zahlungsdienste</div>
          <p>
            Wir binden Zahlungsdienste von Drittunternehmen auf unserer Website
            ein. Wenn Sie einen Kauf bei uns tätigen, werden Ihre Zahlungsdaten
            (z. B. Name, Zahlungssumme, Kontoverbindung, Kreditkartennummer) vom
            Zahlungsdienstleister zum Zwecke der Zahlungsabwicklung verarbeitet.
            Für diese Transaktionen gelten die jeweiligen Vertrags- und
            Datenschutzbestimmungen der jeweiligen Anbieter. Der Einsatz der
            Zahlungsdienstleister erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
            DSGVO (Vertragsabwicklung) sowie im Interesse eines möglichst
            reibungslosen, komfortablen und sicheren Zahlungsvorgangs (Art. 6
            Abs. 1 lit. f DSGVO). Soweit für bestimmte Handlungen Ihre
            Einwilligung abgefragt wird, ist Art. 6 Abs. 1 lit. a DSGVO
            Rechtsgrundlage der Datenverarbeitung; Einwilligungen sind jederzeit
            für die Zukunft widerrufbar.
            <br /><br />
            Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im
            Rahmen dieser Website ein:
          </p>
          <div class="subtitle">Stripe</div>
          <p>
            Anbieter für Kunden innerhalb der EU ist die Stripe Payments Europe,
            Ltd.,1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland
            (im Folgenden „Stripe“).
            <br /><br />
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier: <br />
            <a href="https://stripe.com/de/privacy" target="_blank"
              >https://stripe.com/de/privacy</a
            >
            und <br />
            <a
              href="https://stripe.com/de/guides/general-data-protection-regulation"
              target="_blank"
              >https://stripe.com/de/guides/general-data-protection-regulation</a
            >
            <br /><br />
            Details hierzu können Sie in der Datenschutzerklärung von Stripe
            unter folgendem Link nachlesen: <br />
            <a href="https://stripe.com/de/privacy" target="_blank"
              >https://stripe.com/de/privacy</a
            >
          </p>
        </li>
        <li class="title">
          Audio- und Videokonferenzen
          <div class="subtitle">Datenverarbeitung</div>
          <p>
            Für die Kommunikation mit unseren Kunden setzen wir unter anderen
            Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten Tools
            sind unten aufgelistet. Wenn Sie mit uns per Video- oder
            Audiokonferenz via Internet kommunizieren, werden Ihre
            personenbezogenen Daten von uns und dem Anbieter des jeweiligen
            Konferenz-Tools erfasst und verarbeitet.
            <br /><br />
            Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung
            der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre
            Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der
            Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz,
            Anzahl der Teilnehmer und sonstige „Kontextinformationen“ im
            Zusammenhang mit dem Kommunikationsvorgang (Metadaten).
            <br /><br />
            Des Weiteren verarbeitet der Anbieter des Tools alle technischen
            Daten, die zur Abwicklung der Online- Kommunikation erforderlich
            sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen,
            Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version,
            Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die Art
            der Verbindung.
            <br /><br />
            Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in
            sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf
            den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten
            zählen insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten,
            Voicemails hochgeladene Fotos und Videos, Dateien, Whiteboards und
            andere Informationen, die während der Nutzung des Dienstes geteilt
            werden.
            <br /><br />
            Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die
            Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere
            Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik
            des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung
            durch die Konferenztools entnehmen Sie den Datenschutzerklärungen
            der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt
            haben.
          </p>
          <div class="subtitle">Zweck und Rechtsgrundlagen</div>
          <p>
            Die Konferenz-Tools werden genutzt, um mit angehenden oder
            bestehenden Vertragspartnern zu kommunizieren oder bestimmte
            Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b
            DSGVO). Des Weiteren dient der Einsatz der Tools der allgemeinen
            Vereinfachung und Beschleunigung der Kommunikation mit uns bzw.
            unserem Unternehmen (berechtigtes Interesse im Sinne von Art. 6 Abs.
            1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt
            der Einsatz der betreffenden Tools auf Grundlage dieser
            Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die
            Zukunft widerrufbar.
          </p>
          <div class="subtitle">Speicherdauer</div>
          <p>
            Die unmittelbar von uns über die Video- und Konferenz-Tools
            erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns
            zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt.
            Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
            löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
            unberührt.
            <br /><br />
            Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
            Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
            keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte
            direkt bei den Betreibern der Konferenz-Tools.
          </p>
          <div class="subtitle">Eingesetzte Konferenz-Tools</div>
          <p>Wir setzen folgende Konferenz-Tools ein:</p>
          <div class="subtitle">Zoom</div>
          <p>
            Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom
            Communications Inc., San Jose, 55 Almaden Boulevard, 6th Floor, San
            Jose, CA 95113, USA. Details zur Datenverarbeitung entnehmen Sie der
            Datenschutzerklärung von Zoom: <br />
            <a href="https://zoom.us/de-de/privacy.html" target="_blank"
              >https://zoom.us/de-de/privacy.html</a
            >
            <br /><br />
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier: <br />
            <a href="https://zoom.us/de-de/privacy.html" target="_blank"
              >https://zoom.us/de-de/privacy.html</a
            >
          </p>
        </li>
      </ol>
    </div>
  </div>
</div>
<app-footer></app-footer>
