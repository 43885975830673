<div id="tiles" #tiles>
  <div class="row">
    <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
      <div class="box">
        <div class="icon"></div>
        <div class="box-content">
          <h4>Leistung</h4>
          <p><span class="countup" data-countto="456">0</span> kW (620 PS)</p>
        </div>
      </div>
    </div>
    <div
      class="col-lg-4"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="200"
    >
      <div class="box">
        <div class="icon"></div>
        <div class="box-content">
          <h4>Beschleunigung (0-100 km/h)</h4>
          <p><span class="countup" data-countto="3">0</span> Sek.</p>
        </div>
      </div>
    </div>
    <div
      class="col-lg-4"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      <div class="box">
        <div class="icon"></div>
        <div class="box-content">
          <h4>Höchstgeschwindigkeit</h4>
          <p><span class="countup" data-countto="333">0</span> km/h</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div
      class="col-lg-4"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      <div class="box">
        <div class="icon"></div>
        <div class="box-content">
          <h4>Baujahr</h4>
          <p><span class="countup" data-countto="2016">0</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
